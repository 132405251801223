import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node'
import type { PreferencesCookie } from './.server/cookies/index.js'

import { json } from '@remix-run/node'
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useFetcher, useRouteLoaderData } from '@remix-run/react'

import { preferencesCookie } from './.server/cookies/index.js'
import { HeaderContextProvider } from './components/header.js'
import styles from './styles/global.css?url'

export let links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }]

interface LayoutProps {
  children: React.ReactNode
}

export function Layout({ children }: LayoutProps): JSX.Element {
  let data = useRouteLoaderData<typeof loader>('root')
  let fetcher = useFetcher({ key: 'root' })

  let theme = fetcher.formData?.get('theme') ?? data?.theme
  theme = theme === 'null' ? undefined : theme

  let enableJavaScript = fetcher.formData?.get('enable-javascript') ?? data?.enableJavaScript ?? true
  enableJavaScript = enableJavaScript === 'true' ? true : enableJavaScript === 'false' ? false : enableJavaScript

  return (
    <html
      lang='ru'
      data-theme={theme}
      className='h-full'
    >
      <head>
        <meta charSet='utf-8' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, viewport-fit=cover'
        />
        <Meta />
        <Links />
      </head>

      <body className='bg-secondary text-primary h-full'>
        <HeaderContextProvider>{children}</HeaderContextProvider>

        <ScrollRestoration />

        {enableJavaScript === true && <Scripts />}
      </body>
    </html>
  )
}

export default function App(): JSX.Element {
  return <Outlet />
}

export async function loader({ request }: LoaderFunctionArgs) {
  let header = request.headers.get('cookie')
  let cookie = (await preferencesCookie.parse(header)) as PreferencesCookie | undefined

  return json({
    date: new Date(),
    theme: cookie?.theme,
    enableJavaScript: cookie?.enableJavaScript
  })
}
